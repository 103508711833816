let loadMore = {}

loadMore.install = (Vue, options = {}) => {
    Vue.directive('loadmore', {
        inserted(el, binding) {
            const SELECT_DOWN_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap')
            SELECT_DOWN_DOM.addEventListener('scroll', function (e) {
                const CONDITION =  this.scrollHeight - this.scrollTop <= this.clientHeight
                if (CONDITION) {
                    binding.value()
                }
            })
        },
    })
}
export default loadMore

