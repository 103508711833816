import Vue from 'vue'
import VueRouter from 'vue-router'

// Solve the problem of frequent clicking on menu items in the navigation bar or bottom navigation tabBar in vue-router above version 3.0 causing errors.
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',  // Redirect to the homepage
    name: 'Manager',
    component: () => import('../views/Manager.vue'),
    children: [
      { path: '/403', name: 'Auth', meta: { name: 'Unauthorized' }, component: () => import('../views/manager/Auth') },
      { path: '/home', name: 'Home', meta: { name: 'System Home' }, component: () => import('../views/manager/Home') },
      { path: '/sys/user', name: 'User', meta: { name: 'User Info' }, component: () => import('../views/manager/sys/User.vue') },
      { path: '/sys/role', name: 'Role', meta: { name: 'Role Info' }, component: () => import('../views/manager/sys/Role.vue') },
      { path: '/sys/menu', name: 'Menu', meta: { name: 'Menu Info' }, component: () => import('../views/manager/sys/Menu.vue') },
      { path: '/person', name: 'Person', meta: { name: 'Personal Information' }, component: () => import('../views/manager/Person') },
      { path: '/password', name: 'Password', meta: { name: 'Change Password' }, component: () => import('../views/manager/Password') },
      { path: '/business/news', name: 'News', meta: { name: 'News Information' }, component: () => import('@/views/manager/business/News.vue') },
      { path: '/newsDetail', name: 'NewsDetail', meta: { name: 'News Detail' }, component: () => import('../views/manager/NewsDetail') },
      { path: '/sys/notice', name: 'Notice', meta: { name: 'System Notice' }, component: () => import('../views/manager/sys/Notice.vue') },
      { path: '/sys/log', name: 'Logs', meta: { name: 'System Logs' }, component: () => import('../views/manager/sys/Logs.vue') },
      { path: '/business/dataStatistics', name: 'Charts', meta: { name: 'Data Statistics' }, component: () => import('@/views/manager/business/Charts.vue') },
      { path: '/business/order', name: 'order', meta: { name: 'Order Management' }, component: () => import('@/views/manager/business/Order.vue') },
      { path: '/business/custom', name: 'custom', meta: { name: 'Custom Management' }, component: () => import('@/views/manager/business/Custom.vue') },
      { path: '/customer/complain', name: 'custom', meta: { name: 'Complain Management' }, component: () => import('@/views/manager/customer/Complain.vue') },
      { path: '/product/productList', name: 'product', meta: { name: 'Product Management' }, component: () => import('@/views/manager/product/productList.vue') },
      { path: '/suppliers/suppliersList', name: 'product', meta: { name: 'Suppliers Management' }, component: () => import('@/views/manager/suppliers/suppliersList.vue') },
      { path: '/product/salesRanking', name: 'product', meta: { name: 'SalesRanking Management' }, component: () => import('@/views/manager/product/salesRanking.vue') },
    ]
  },
  { path: '/login', name: 'Login', meta: { name: 'Login' }, component: () => import('../views/Login.vue') },
  { path: '/register', name: 'Register', meta: { name: 'Register' }, component: () => import('../views/Register.vue') },
  { path: '*', name: '404', meta: { name: 'Page Not Found' }, component: () => import('../views/404.vue') },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   // 'to' is the route we're navigating to
//   // 'from' is the current route
//   // 'next' is a function to help us navigate to a route
//   let adminPaths = ['/user']
//   let user = JSON.parse(localStorage.getItem('honey-user') || '{}')
//
//   // if (user.role !== 'admin' && adminPaths.includes(to.path)) {
//   //   // If the current logged-in user is not an admin and the path being navigated to is admin-only, redirect to a page without permission instead of the actual page
//   //   next('/403')
//   // } else {
//   //   next()
//   // }
// })

export default router
